.inputContainer {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid var(--grey-35);
  border-radius: 6px;
  color: var(--grey-35);
  svg {
    position: relative;
    top: 28px;
    left: 5px;
  }
  input {
    position: relative;
    width: 85%;
    margin: 0;
    display: inline-block;
    border: none;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 25px;
    font-family: var(--font-family);
    color: var(--dark-grey-text);
    height: 74px;
  }
  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--grey-35);
    opacity: 1; /* Firefox */
  }

  input:focus {
    outline: none;
    border: none;
  }
}
.disabled {
  background: #f4f7fa;
  border: none;
}

.inputContainer:focus-within {
  color: var(--grey);
  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--grey);
    opacity: 1; /* Firefox */
  }
  border: 1px solid var(--green-medium);
  box-shadow: 0 0 0 5px var(--green-light);
  position: relative; // this enables the input in focus to be in top
}
.bottomInput {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.topInput {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.inputError,
.inputError:focus-within {
  border-color: var(--error-red);
  box-shadow: none;

  &.topInput {
    border-bottom: 1px solid var(--error-red);
  }
}
.disabled {
  background: #f4f7fa;
  border: none;
  input {
    font-size: 18px;
  }
}

@media only screen and (max-width: 550px) {
  .inputContainer {
    height: 46px;
    input {
      height: 27px;
      font-size: 16px;
      top: 9px;
    }
    input::placeholder {
      font-size: 16px;
    }
    svg {
      top: 15px;
      left: 3px;
    }
  }

  .inputContainer:focus-within {
    box-shadow: 0 0 0 3px var(--green-light);

    .disabled {
      box-shadow: none;
    }
  }
  .forgotPassword {
    text-align: center;
    position: relative;
    font-size: 10px;
  }
}

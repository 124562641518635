.container {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 800px;

  .formWrapper {
    height: 485px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

    .socialProviderWrapper {
      height: 75px;
      text-align: center;
      .socialProviderSeparator {
        width: 95px;
        display: flex;
        justify-content: space-between;
        position: relative;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      hr {
        width: 100%;
        border: 1px solid var(--text-color-tertiary);
      }
      span {
        position: relative;
        bottom: 1px;
        font-size: 13px;
        padding: 0 5px;
        color: var(--text-color-tertiary);
      }
    }
    .loginForm {
      position: absolute;
      left: 50%;
      top: 63%;
      height: 363px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: table-cell;
      width: 444px;
      input {
        background-color: transparent;
      }

      .forgotPassword,
      .errorMessage {
        a {
          color: var(--text-color-tertiary);
          text-decoration: underline;

          &:hover {
            color: var(--color-positive);
          }
        }

        position: relative;
        top: 10px;
        left: 4px;
        text-align: left;
      }

      .errorMessageExist {
        top: 21px;
      }

      .buttons {
        margin-top: 50px;
      }

      .inputErrors {
        border-bottom: none;
      }

      .topInputError {
        border-top: none;
      }

      .bottomInputError {
        border-bottom: none;
      }
    }
    .withSocialProvider {
      top: 60%;
    }
  }
  .withSocialProvider {
    top: 46%;
  }

  @media only screen and (max-width: 1500px) {
    height: 620px;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    height: 600px;
    .formWrapper {
      height: 377px;
      width: 400px;
      .loginForm {
        width: 305px;
        height: 292px;
        margin-top: 50px;
        .forgotPassword {
          text-align: center;
          position: relative;
        }
      }
    }
  }
}

.passwordResetSuccess {
  margin: auto;
  padding-top: 250px;
  width: 444px;

  .headerContainer {
    padding-bottom: var(--space-l);
  }

  @media only screen and (max-width: 550px) {
    width: 305px;
    padding-top: 120px;
  }
}

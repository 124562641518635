.footer {
  text-align: center;
  right: 0;
  left: 0;
  font-size: 13px;
  a {
    color: var(--text-color-tertiary);
  }
  color: var(--text-color-tertiary);
  height: 80px;

  @media only screen and (max-width: 550px) {
    margin-top: 100px;
  }
}

.congratulations {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 800px;
  .content {
    margin: auto;
    padding-top: 100px;
    width: 444px;
    text-align: center;
    .details {
      .svg {
        height: 80px;
      }
      .welcome {
        padding: var(--space-l) 0;
      }
      .nextSteps {
        padding: var(--space-m) 68px;
        background: var(--background-color-knowledge);
        border-radius: 8px;
      }
      .button {
        padding-top: var(--space-l);
        text-align: center;
      }
    }

    @media only screen and (max-width: 550px) {
      width: 305px;
      padding-top: 120px;
    }
  }

  @media only screen and (max-width: 1500px) {
    height: 650px;
  }
}

.app {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: fit-content;

  .form {
    left: 0;
    margin-bottom: 40px;
    position: relative;
    right: 0;
  }
}

.logo {
  cursor: pointer;
  position: relative;
  top: 25px;
  left: 34px;

  a {
    cursor: pointer;
  }

  @media only screen and (max-width: 550px) {
    left: 33%;
  }
}

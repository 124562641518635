.container {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;

  .formWrapper {
    height: 485px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;

    .loginForm {
      position: absolute;
      left: 50%;
      top: 63%;
      height: 363px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: table-cell;
      width: 444px;

      .forgotPassword,
      .buttons {
        margin-top: 50px;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .container {
    .formWrapper {
      height: 377px;
      .loginForm {
        width: 305px;
        height: 292px;
      }
    }
  }
}

.marginTop {
  margin-top: 70px;

  @media only screen and (max-width: 550px) {
    margin-top: 40px;
  }
}

.socialProviderContainer {
  bottom: 36px;
  height: 800px;
  padding: 0;
  width: 100vw;

  .formWrapper {
    height: 485px;
    position: relative;
    left: 50%;
    top: 53%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    .titleContainer {
      width: 450px;
      text-align: center;
      margin: auto;
      position: relative;
      bottom: 45px;
    }
    .loginForm {
      position: absolute;
      left: 50%;
      top: 60%;
      height: fit-content;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: table-cell;
      width: 444px;
      margin-top: var(--space-l);

      .input {
        height: 56px;
        input,
        input::placeholder {
          font-size: 18px;
        }
        input {
          height: 54px;
        }
        svg {
          top: 19px;
        }
      }

      .inputErrors {
        border-bottom: none;
      }

      .topInputError {
        border-top: none;
      }

      .bottomInputError {
        border-bottom: none;
      }

      .email {
        height: 108px;
      }

      .hiddenInput {
        display: none;
      }

      .errorMessage {
        margin: 2px 0 13px;
        position: relative;
      }

      .buttons {
        margin-top: 50px;
      }

      .forgotPasswordLink {
        text-decoration: underline;
        color: var(--text-color-tertiary);
        &:hover {
          color: var(--color-positive);
        }
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    height: 620px;
  }
}

@media only screen and (max-width: 550px) {
  .socialProviderContainer {
    height: 650px;
    .formWrapper {
      top: 50%;
      height: 377px;
      .titleContainer {
        width: 400px;
      }
      .loginForm {
        width: 305px;
        margin-top: 130px;
        .input {
          input {
            height: 40px;
          }
        }
      }
    }
  }
}

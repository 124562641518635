.checkbox {
  input {
    display: none;

    &:checked,
    &:checked:disabled {
      + label {
        &::before {
          animation-name: none;
          background: var(--white);
          box-shadow: 0 0 0 1px var(--white);
        }
        &::after {
          display: block;
        }
      }
    }
  }

  label {
    color: var(--grey);
    cursor: pointer;
    display: block;
    font-family: var(--font-family);
    font-size: 13px;
    margin: 4px 0 0;
    padding-left: 30px;
    position: relative;

    &::before {
      border-radius: 3px;
      box-shadow: 0 0 0 1px var(--grey-35);
      content: '';
      height: 16px;
      left: 0;
      margin: 6px 0 0 1px;
      position: absolute;
      width: 16px;
    }

    &::after {
      border: 2px solid var(--dark-grey-text);
      border-right: 0;
      border-top: 0;
      content: '';
      display: none;
      height: 6px;
      left: 8px;
      margin: 6px 0 0 1px;
      position: absolute;
      top: 7px;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 9px;
    }
  }
}

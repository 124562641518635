.spinnerContainer {
  margin: auto;
  animation: fadein 0.8s;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50%;
  span {
    margin-top: 40px;
    font-size: 24px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 800px;

  .formWrapper {
    height: 485px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    .loginForm {
      position: absolute;
      left: 50%;
      top: 63%;
      height: 363px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: table-cell;
      width: 444px;

      .forgotPassword,
      .errorMessage {
        span {
          color: var(--red-light);
        }
        position: relative;
        top: 10px;
        left: 4px;
        font-size: 13px;
      }
      .errorMessageExist {
        top: 21px;
      }
      .loginFormWrapper {
        a {
          text-decoration: underline;
          color: var(--text-color-tertiary);
          display: block;
          text-align: center;
          padding-top: 60px;
          &:hover {
            color: var(--color-positive);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    height: 620px;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    .formWrapper {
      height: 377px;
      .loginForm {
        width: 305px;
        height: 292px;
        .forgotPassword {
          text-align: center;
          position: relative;
          font-size: 10px;
        }
      }
    }
  }
}

.bottomMargin {
  margin-bottom: 10px;
}

.socialProviderButton {
  border: 1px solid var(--text-color-inactive) !important;
  height: 56px !important;
  width: 100% !important;
  font-size: 18px !important;

  .textContainer {
    align-items: center;
    display: flex;

    .icon {
      position: relative;
      right: 14px;
    }
  }
}

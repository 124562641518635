.container {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 800px;

  .wrapper {
    margin: auto;
    padding-top: 100px;
    width: 444px;
    text-align: center;
    .svg {
      height: 180px;
    }

    @media only screen and (max-width: 550px) {
      width: 305px;
      padding-top: 120px;
    }
  }

  @media only screen and (max-width: 1500px) {
    height: 650px;
  }
}

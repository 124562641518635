.container {
  padding: 0 var(--space-l);
  margin: 0;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .formWrapper {
    width: 100%;
    max-width: 444px;
    margin-top: 50px;

    &.selectTypePage {
      margin-bottom: 17px;
      margin-top: 240px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      text-align: center;
      margin-bottom: 0;
      margin-top: 0;
    }
    .socialProviderWrapper {
      height: 75px;
      text-align: center;
      .socialProviderSeparator {
        width: 95px;
        display: flex;
        justify-content: space-between;
        position: relative;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      hr {
        width: 100%;
        border: 1px solid var(--text-color-tertiary);
      }
      span {
        position: relative;
        bottom: 1px;
        font-size: 13px;
        padding: 0 5px;
        color: var(--text-color-tertiary);
      }
    }
    .form {
      width: 100%;

      .errorMessage {
        span {
          color: var(--error-red);
        }
        margin-top: 10px;
        margin-left: 4px;
        font-size: 13px;
      }

      .errorMessageExist {
        top: 21px;
      }

      .buttons {
        margin-top: 32px;
      }

      .inputErrors {
        border-bottom: none;
      }

      .topInputError {
        border-top: none;
      }

      .bottomInputError {
        border-bottom: none;
      }
    }
    &.withSocialProvider {
      margin-top: 100px;
    }
  }

  @media only screen and (max-width: 1500px) {
    min-height: 620px;
  }
}

.inviteSubTitleDescriptionContainer {
  margin-bottom: -36px;
}

p {
  padding-top: var(--space-xs);
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.buttonsContainer {
  margin-bottom: 102px;
  a {
    color: var(--text-color-tertiary);
    text-decoration: underline;
  }
}

.registrationFormContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form,
.registrationFormContainer {
  .subTitle {
    p {
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 30px;
      text-align: center;
      font-weight: lighter;
    }
  }
}
.bottomInputBlock {
  margin-top: 52px;
}

@media only screen and (max-width: 550px) {
  .container {
    min-height: 700px;

    .formWrapper {
      height: 377px;
      h1 {
        top: 25%;
      }
      .form {
        max-width: 305px;
        width: 100%;
      }
    }
  }
}

.container {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 800px;

  .wrapper {
    margin: auto;
    padding-top: 200px;
    width: 444px;
    .textContainer {
      padding-bottom: 35px;
    }

    @media only screen and (max-width: 550px) {
      width: 305px;
    }
  }

  @media only screen and (max-width: 1500px) {
    height: 650px;
  }
}

.button-container {
  margin-top: 8px;
}

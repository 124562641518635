.informativeLinkContainer {
  margin-top: 30px;
  text-align: center;
  color: var(--text-color-tertiary);

  a {
    color: var(--text-color-positive);
    margin-left: 5px;
    text-decoration: none;

    .arrow {
      margin-left: 5px;
    }
  }

  @media only screen and (max-width: 550px) {
    display: grid;
    text-align: center;
    position: relative;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

:root {
  --font-family: 'Poppins', sans-serif;
  --green-medium: #24a979;
  --green-light: #e8f9e4;
  --light-grey: #f2f2f2;
  --grey-35: #a1b1c1;
  --grey-30: #77859c;
  --grey-20: #415068;
  --grey: #67788f;
  --white: #ffffff;
  --purple-accent: #5656a6;
  --black-green: #002b27;
  --grey-text: #33445c;
  --dark-grey-text: #415068;
  --error-red: #ed6f78;
}

body {
  border: 0;
  font-family: var(--font-family);
  height: 100vh;
  margin: 0;
  outline: 0;
  overflow-x: hidden;
  padding: 0;
  vertical-align: baseline;
  width: 100vw;
}

.app {
  .logo {
    position: absolute;
    top: 25px;
    left: 34px;
  }
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
  .form {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 36px;
  }
}

@media only screen and (max-width: 550px) {
  .logo {
    left: 33% !important;
  }
}
